

























import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import CostBasis from '@/components/clients/tabs/costBasis.vue';
import Transactions from '@/components/clients/tabs/transactions.vue';
import Users from '@/components/clients/tabs/users.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';
import UiSelect from '@/components/ui/UiSelect.vue';

import { baConfig } from '../../config';
import { ApiSvcOrgResponseDTO, ClientDataApi } from '../../generated/api-svc';

export interface Client {
  orgId: string;
  name: string;
}

@Component({
  components: {
    UiSelect,
    Transactions,
    CostBasis,
    Users,
    UiPageHeader,
  },
})
export default class Clients extends BaseVue {
  public clients: ApiSvcOrgResponseDTO[] = [];
  public selectedClient: string | null = null;
  public selectedTab = 'transactions';

  public get clientList() {
    const clientList: Record<string, string> = {};
    for (const c of this.clients) {
      clientList[c.name] = c.id!;
    }
    return clientList;
  }

  public isLoading = false;

  public headers = [
    { id: 'type', label: 'Type', defaultVisibility: true, defaultWidth: '52px' },
    { id: 'id', label: 'Transaction Id', defaultVisibility: true, defaultWidth: '150px' },
    { id: 'date', label: 'Date', defaultVisibility: true, defaultWidth: '196px' },
    { id: 'amounts', label: 'Amounts', defaultVisibility: true, defaultWidth: '94px' },
    { id: 'tokens', label: 'Tokens', defaultVisibility: true, defaultWidth: '83px' },
  ];

  async mounted() {
    const clientApi = new ClientDataApi(undefined, baConfig.getFriendlyApiUrl());
    const resp = await clientApi.getClients(this.orgId, { withCredentials: true });
    this.clients = resp.data.items;
    this.selectedClient = this.clientList[0];
  }

  public getTabClass(id: string) {
    if (id === this.selectedTab) {
      return 'tw-border-primary-300 tw-text-gray-700 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    } else {
      return 'tw-border-transparent tw-text-gray-500 hover:tw-text-gray-700 hover:tw-border-gray-300 tw-whitespace-nowrap tw-pb-2 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm';
    }
  }
}
